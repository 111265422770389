<template>
  <ModalSide @close="closeModal">
    <template v-slot:tabs>
      <button
        v-if="!editMode"
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>{{ translations.storeDetails }}</span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>

      <button
        v-if="editMode"
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>{{ translations.storeDetails }}</span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>
    </template>

    <template v-slot:content>
      <ModalSideContent v-if="!editMode">
        <div class="mb-8 flex justify-between">
          <div class="flex">
            <div class="flex-shrink-0">
              <StoresAvatar :name="store.themeColor" class="h-16 w-16" />
            </div>
            <div class="flex flex-col justify-center pl-3">
              <div class="font-medium text-neutral-900 md:text-lg">
                {{ store.name }}
              </div>
              <div class="text-sm text-neutral-400">
                {{ store.organisation.name }}
              </div>
            </div>
          </div>
          <div class="ml-2 flex flex-shrink-0 items-start">
            <BaseButton
              v-if="$can('write:stores')"
              size="medium"
              variant="outline"
              @buttonClick="openEditMode"
            >
              <template #iconCenter>
                <IconEdit class="text-neutral-300" />
              </template>
            </BaseButton>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="mb-4 w-1/2">
            <div class="text-sm font-medium text-neutral-900">
              {{ translations.streetAddress }}
            </div>
            <div class="text-sm-relaxed text-neutral-400">
              {{ store.streetAddress }}
            </div>
          </div>
          <div class="mb-4 w-1/2">
            <div class="text-sm font-medium text-neutral-900">
              {{ translations.city }}
            </div>
            <div class="text-sm-relaxed text-neutral-400">
              {{ store.city }}
            </div>
          </div>
          <div class="w-1/2">
            <div class="text-sm font-medium text-neutral-900">
              {{ translations.zipCode }}
            </div>
            <div class="text-sm-relaxed text-neutral-400">
              {{ store.zipCode }}
            </div>
          </div>
          <div class="w-1/2">
            <div class="text-sm font-medium text-neutral-900">
              {{ translations.country }}
            </div>
            <div class="flex items-center text-sm-relaxed text-neutral-400">
              <country-flag :country="store.country.code" size="small" />
              <span class="ml-2">{{ countryName }}</span>
            </div>
          </div>
        </div>
      </ModalSideContent>
      <ModalSideContent
        v-if="!editMode && store.users.length > 0"
        class="flex-grow"
      >
        <div class="pr-5 text-xs font-medium uppercase">
          {{ translations.leadership }}
        </div>
        <UserList :users="store.users" />
      </ModalSideContent>

      <ModalSideContent v-if="editMode" class="flex-grow overflow-y-scroll">
        <StoresForm
          :current-store="store"
          :organisations="organisations"
          :users="users"
          :country-options="countryOptions"
          @updateThemeColor="updateThemeColor"
          @updateName="updateName"
          @updateStreetAddress="updateStreetAddress"
          @updateZipCode="updateZipCode"
          @updateCity="updateCity"
          @updateOrganisationUuid="updateOrganisationUuid"
          @updateCountryUuid="updateCountryUuid"
          @updateSelectedUserUuids="updateSelectedUserUuids"
        />
        <div
          v-if="formError"
          class="bg-danger-0 rounded-sm border-l-2 border-danger-100 px-3 py-2 text-xs font-medium text-danger-500"
        >
          {{ formError }}
        </div>
      </ModalSideContent>
      <ModalSideActions v-if="editMode" class="justify-between">
        <div class="text-sm text-neutral-900">
          <div v-if="!disbandConfirmationVisible">
            <button
              :class="['focus:outline-none hidden font-medium sm:flex']"
              @click="showDisbandConfirmation"
            >
              {{ $t("global.delete") }}
            </button>
          </div>
          <div v-else>
            {{ $t("global.areYouSure") }}
            <button
              class="focus:outline-none ml-2 inline-block font-medium"
              @click="deleteStore"
            >
              {{ $t("global.yes") }}
            </button>
            <button
              class="focus:outline-none ml-2 inline-block font-medium"
              @click="hideDisbandConfirmation"
            >
              {{ $t("global.no") }}
            </button>
          </div>
        </div>
        <div class="flex">
          <BaseButton
            :text="$t(`global.cancel`)"
            size="medium"
            variant="outline"
            @buttonClick="closeModal"
          ></BaseButton>

          <BaseButton
            :text="$t(`global.saveChanges`)"
            size="medium"
            variant="fill"
            class="ml-2"
            @buttonClick="submit"
          >
          </BaseButton>
        </div>
      </ModalSideActions>
    </template>
  </ModalSide>
</template>

<script>
import ModalSide from "@/components/common/ModalSide";
import ModalSideContent from "@/components/common/ModalSideContent";
import ModalSideActions from "@/components/common/ModalSideActions";

import StoresAvatar from "@/components/user-management/stores/StoresAvatar";
import StoresForm from "@/components/user-management/stores/StoresForm";
import UserList from "@/components/user-management/UserList";

import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import UPDATE_STORE from "@/graphql/UpdateStore.gql";
import FIND_STORES from "@/graphql/FindStores.gql";
import DELETE_STORE from "@/graphql/DeleteStore.gql";

export default {
  components: {
    ModalSide,
    ModalSideContent,
    ModalSideActions,
    StoresAvatar,
    StoresForm,
    UserList,
  },

  props: {
    store: {
      type: Object,
      required: true,
    },
    organisations: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    countryOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      formError: null,
      editMode: false,
      editedName: this.store.name,
      editedThemeColor: this.store.themeColor,
      editedStreetAddress: this.store.streetAddress,
      editedCity: this.store.city,
      editedZipCode: this.store.zipCode,
      editedOrganisationUuid: this.store.organisation.uuid,
      editedCountryUuid: this.store.country.uuid,
      editedUserUuids: this.store.users.map((user) => user.uuid),
      disbandConfirmationVisible: false,
      translations: {
        storeDetails: this.$t(
          "components.userManagement.stores.storesForm.storeDetails",
        ),
        streetAddress: this.$t(
          "components.userManagement.stores.storesForm.streetAddress",
        ),
        city: this.$t("components.userManagement.stores.storesForm.city"),
        zipCode: this.$t("components.userManagement.stores.storesForm.zipCode"),
        country: this.$t("components.userManagement.stores.storesForm.country"),
        leadership: this.$t(
          "components.userManagement.stores.storesForm.leadership",
        ),
        removeStore: this.$t(
          "components.userManagement.stores.storesForm.addAnotherUser",
        ),
      },
    };
  },

  computed: {
    countryName() {
      return countries.getName(this.store.country.code, this.$i18n.locale);
    },
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },
    openEditMode() {
      this.editMode = true;
    },
    closeEditMode() {
      this.editMode = false;
      this.formError = null;
    },
    updateThemeColor(storeThemeColor) {
      this.editedThemeColor = storeThemeColor;
    },
    updateName(storeName) {
      this.editedName = storeName;
    },
    updateStreetAddress(storeStreetAddress) {
      this.editedStreetAddress = storeStreetAddress;
    },
    updateCity(storeCity) {
      this.editedCity = storeCity;
    },
    updateZipCode(storeZipCode) {
      this.editedZipCode = storeZipCode;
    },
    updateOrganisationUuid(storeOrganisationUuid) {
      this.editedOrganisationUuid = storeOrganisationUuid;
      this.$emit("selectedOrganisationUuid", storeOrganisationUuid);
    },
    updateCountryUuid(storeCountryUuid) {
      this.editedCountryUuid = storeCountryUuid;
    },
    updateSelectedUserUuids(selectedUsers) {
      this.editedUserUuids = selectedUsers;
    },
    showDisbandConfirmation() {
      this.disbandConfirmationVisible = true;
    },
    hideDisbandConfirmation() {
      this.disbandConfirmationVisible = false;
    },
    deleteStore() {
      this.$apollo
        .mutate({
          mutation: DELETE_STORE,
          variables: {
            uuid: this.store.uuid,
          },
          update: (store, { data: { deletedStore } }) => {
            const data = store.readQuery({
              query: FIND_STORES,
            });

            const index = data.stores.findIndex(
              (store) => store.uuid == deletedStore.uuid,
            );

            if (index !== -1) {
              data.stores.splice(index, 1);

              store.writeQuery({
                query: FIND_STORES,
                data,
              });
            }
          },
        })
        .then(() => {
          this.$emit("updateUserUuids");
          this.closeModal();
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message); //@TODO: replace with proper error logging later and remove formError cause it's never rendered
        });
    },
    submit() {
      this.$apollo
        .mutate({
          mutation: UPDATE_STORE,
          variables: {
            uuid: this.store.uuid,
            name: this.editedName,
            themeColor: this.editedThemeColor,
            streetAddress: this.editedStreetAddress,
            zipCode: this.editedZipCode,
            city: this.editedCity,
            organisationUuid: this.editedOrganisationUuid,
            countryUuid: this.editedCountryUuid,
            userUuids: this.editedUserUuids,
          },
        })
        .then(() => {
          this.$emit("updateUserUuids");
          this.closeModal();
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message);
        });
    },
  },
};
</script>

<template>
  <div class="grid gap-6">
    <StoresIconRadios
      v-model="themeColor"
      :label="translations.storeInterior"
      @input="updateThemeColor"
    />

    <TextField
      v-model="name"
      :label="translations.nameOfStore"
      :placeholder="translations.nameOfStorePlaceholder"
      @input="updateName"
    />
    <TextField
      v-model="streetAddress"
      :label="translations.streetAddress"
      :placeholder="translations.streetAddressPlaceholder"
      @input="updateStreetAddress"
    />

    <div class="md:-mx-2 md:flex">
      <div class="w-full md:px-2">
        <TextField
          v-model="zipCode"
          :label="translations.zipCode"
          :placeholder="translations.zipCodePlaceholder"
          @input="updateZipCode"
        />
      </div>
      <div class="w-full md:px-2">
        <TextField
          v-model="city"
          :label="translations.city"
          :placeholder="translations.cityPlaceholder"
          @input="updateCity"
        />
      </div>
    </div>
    <SelectField
      v-model="countryUuid"
      :label="translations.country"
      @input="updateCountryUuid"
    >
      <option value="-1" selected disabled>
        {{ translations.countryPlaceholder }}
      </option>
      <option
        v-for="country in countryOptions"
        :key="country.uuid"
        :value="country.uuid"
        :selected="countryUuid === country.uuid"
      >
        {{ countryName(country) }}
      </option>
    </SelectField>
    <SelectField
      v-model="organisationUuid"
      :label="translations.partOfOrganisation"
      @input="updateOrganisationUuid"
    >
      <option value="-1" selected disabled>
        {{ translations.partOfOrganisationPlaceholder }}
      </option>
      <option
        v-for="organisation in organisations"
        :key="organisation.uuid"
        :value="organisation.uuid"
        :selected="organisationUuid === organisation.uuid"
      >
        {{ organisation.name }}
      </option>
    </SelectField>
    <ListSelector
      :label="translations.addUsers"
      :add-text="translations.addUser"
      :item-options="availableUsers"
      :selected-item-options="selectedUsers"
      :disabled="disableUsers"
      @updateSelectedItems="updateSelectedUsers"
    />
  </div>
</template>

<script>
import StoresIconRadios from "@/components/user-management/stores/StoresIconRadios";
import ListSelector from "@/components/common/ListSelector";

import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  components: {
    StoresIconRadios,
    ListSelector,
  },
  props: {
    currentStore: {
      type: Object,
      default: () => {
        return {
          themeColor: null,
          name: null,
          streetAddress: null,
          organisation: {
            uuid: "-1",
          },
          country: {
            uuid: "-1",
          },
          zip: null,
          city: null,
          users: [],
        };
      },
    },
    organisations: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    countryOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    const { themeColor, name, streetAddress, city, zipCode } =
      this.currentStore;
    return {
      themeColor,
      name,
      streetAddress,
      city,
      zipCode,
      storeUsers: this.currentStore.users,
      organisationUuid: this.currentStore.organisation.uuid,
      countryUuid: this.currentStore.country.uuid,
      translations: {
        nameOfStore: this.$t(
          "components.userManagement.stores.storesForm.nameOfStore",
        ),
        nameOfStorePlaceholder: this.$t(
          "components.userManagement.stores.storesForm.nameOfStorePlaceholder",
        ),
        storeInterior: this.$t(
          "components.userManagement.stores.storesForm.storeInterior",
        ),
        partOfOrganisation: this.$t(
          "components.userManagement.stores.storesForm.partOfOrganisation",
        ),
        partOfOrganisationPlaceholder: this.$t(
          "components.userManagement.users.usersForm.partOfOrganisationPlaceholder",
        ),
        country: this.$t("components.userManagement.stores.storesForm.country"),
        countryPlaceholder: this.$t(
          "components.userManagement.stores.storesForm.countryPlaceholder",
        ),
        addUsers: this.$t(
          "components.userManagement.stores.storesForm.addUsers",
        ),
        addUser: this.$t("components.userManagement.stores.storesForm.addUser"),
        streetAddress: this.$t(
          "components.userManagement.stores.storesForm.streetAddress",
        ),
        streetAddressPlaceholder: this.$t(
          "components.userManagement.stores.storesForm.streetAddressPlaceholder",
        ),
        city: this.$t("components.userManagement.stores.storesForm.city"),
        cityPlaceholder: this.$t(
          "components.userManagement.stores.storesForm.cityPlaceholder",
        ),
        zipCode: this.$t("components.userManagement.stores.storesForm.zipCode"),
        zipCodePlaceholder: this.$t(
          "components.userManagement.stores.storesForm.zipCodePlaceholder",
        ),
      },
    };
  },

  computed: {
    availableUsers() {
      return this.users.map((user) => ({
        title: this.userTitle(user),
        subTitle: user.role?.description || user.role?.name,
        key: user.uuid,
      }));
    },
    selectedUsers() {
      return this.storeUsers.map((user) => ({
        title: this.userTitle(user),
        subTitle: user.role?.description || user.role?.name,
        key: user.uuid,
      }));
    },
    disableUsers() {
      return this.organisationUuid === "-1";
    },
  },

  methods: {
    userTitle(user) {
      return user.firstName && user.lastName
        ? `${user.firstName} ${user.lastName}`
        : user.email;
    },
    updateThemeColor() {
      this.$emit("updateThemeColor", this.themeColor);
    },
    updateName() {
      this.$emit("updateName", this.name);
    },
    updateStreetAddress() {
      this.$emit("updateStreetAddress", this.streetAddress);
    },
    updateOrganisationUuid() {
      this.$emit("updateOrganisationUuid", this.organisationUuid);
    },
    updateCountryUuid() {
      this.$emit("updateCountryUuid", this.countryUuid);
    },
    updateZipCode() {
      this.$emit("updateZipCode", this.zipCode);
    },
    updateCity() {
      this.$emit("updateCity", this.city);
    },
    updateSelectedUsers(selectedItems) {
      const selectedUserUuids = selectedItems.map((item) => item.key);
      this.$emit("updateSelectedUserUuids", selectedUserUuids);
    },
    countryName(country) {
      return countries.getName(country.code, this.$i18n.locale);
    },
  },
};
</script>

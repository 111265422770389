<template>
  <div class="mb-2">
    <div class="mb-3 block text-sm font-medium text-neutral-700">
      {{ label }}
    </div>
    <div class="flex">
      <div v-for="theme in themes" :key="theme.name" class="mr-4">
        <label class="group relative flex cursor-pointer">
          <input
            v-model="localValue"
            type="radio"
            name="store-icon"
            :value="theme.name"
            class="absolute opacity-0"
          />
          <div>
            <div
              class="selected-ring absolute -mt-1 -ml-1 h-14 w-14 rounded-full border-2 opacity-0 transition-all duration-200 group-hover:opacity-25"
            ></div>
            <StoresAvatar :name="theme.name" />
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import StoresAvatar from "@/components/user-management/stores/StoresAvatar";
import storeThemes from "@/data/storeThemes.js";

export default {
  components: {
    StoresAvatar,
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      localValue: null,
      themes: storeThemes,
    };
  },

  created() {
    this.localValue = this.value;

    this.$watch("localValue", (value) => {
      this.$emit("input", value);
    });
  },
};
</script>

<style scoped>
input:checked + div .selected-ring {
  @apply opacity-100;
}
</style>

const storeThemes = [
  {
    icon: "IconBorettisMint",
    name: "mint",
  },
  {
    icon: "IconBorettisPesto",
    name: "pesto",
  },
  {
    icon: "IconBorettisWine",
    name: "wine",
  },
  {
    icon: "IconBorettisRose",
    name: "rose",
  },
];
export default storeThemes;

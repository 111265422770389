<template>
  <div class="sm:container">
    <TableFrame>
      <TableHeader>
        <template v-slot:title>
          {{ $t("pages.manageStores.title") }}
        </template>
        <template v-slot:description>
          {{ $t("pages.manageStores.description") }}
        </template>
        <template v-slot:actions>
          <BaseButton
            v-if="$can('write:stores')"
            size="medium"
            variant="fill"
            text="Create new store"
            @buttonClick="openModalCreateStores"
          >
            <template #iconLeft>
              <IconPlus />
            </template>
          </BaseButton>
        </template>
      </TableHeader>
      <StoresTableLegend />
      <StoresTableRow
        v-for="store in stores"
        :key="store.uuid"
        :store="store"
        @open="openModalDetailsStores"
      />
    </TableFrame>
    <transition name="fade">
      <StoresModalCreate
        v-if="modalStoresCreateOpen"
        :organisations="organisations"
        :users="users"
        :country-options="countries"
        @close="closeModal"
        @selectedOrganisationUuid="findUsersForOrganisation"
      />
      <StoresModalDetails
        v-if="modalStoresDetailsOpen"
        :store="storeDetails"
        :organisations="organisations"
        :users="users"
        :country-options="countries"
        @close="closeModal"
        @selectedOrganisationUuid="findUsersForOrganisation"
      />
    </transition>
  </div>
</template>

<script>
import TableFrame from "@/components/common/table/TableFrame";
import TableHeader from "@/components/common/table/TableHeader";
import StoresTableLegend from "@/components/user-management/stores/StoresTableLegend";
import StoresTableRow from "@/components/user-management/stores/StoresTableRow";
import StoresModalDetails from "@/components/user-management/stores/StoresModalDetails";
import StoresModalCreate from "@/components/user-management/stores/StoresModalCreate";

import FIND_ORGANISATION_OPTIONS from "@/graphql/FindOrganisationOptions.gql";
import FIND_COUNTRIES from "@/graphql/FindCountries.gql";
import FIND_STORES from "@/graphql/FindStores.gql";
import FIND_USERS_FROM_ORGANISATION from "@/graphql/FindUsersFromOrganisation.gql";

export default {
  name: "ManageStoreLocations",
  components: {
    TableFrame,
    TableHeader,
    StoresTableLegend,
    StoresTableRow,
    StoresModalDetails,
    StoresModalCreate,
  },

  data() {
    return {
      stores: [],
      users: [],
      modalStoresCreateOpen: false,
      modalStoresDetailsOpen: false,
      storeDetails: "",
    };
  },

  apollo: {
    organisations: {
      query: FIND_ORGANISATION_OPTIONS,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    countries: {
      query: FIND_COUNTRIES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    stores: {
      query: FIND_STORES,
      variables: {
        withoutPreOpening: false,
      },
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
  },

  methods: {
    openModalCreateStores() {
      this.modalStoresCreateOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },
    openModalDetailsStores(store) {
      this.storeDetails = store;
      this.modalStoresDetailsOpen = true;
      this.findUsersForOrganisation(store.organisation.uuid);
      document.body.classList.add("overflow-y-hidden");
    },
    closeModal() {
      this.modalStoresDetailsOpen = false;
      this.modalStoresCreateOpen = false;
      document.body.classList.remove("overflow-y-hidden");
    },
    async findUsersForOrganisation(organisationUuid) {
      const queryResult = await this.$apollo.query({
        query: FIND_USERS_FROM_ORGANISATION,
        variables: {
          organisationUuid,
        },
        error(error) {
          this.error = JSON.stringify(error.message);
        },
      });
      this.users = queryResult.data.users;
    },
  },
};
</script>

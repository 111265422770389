<template>
  <component :is="storeIcon" class="h-12 w-12 flex-shrink-0"></component>
</template>

<script>
import storeThemes from "@/data/storeThemes.js";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      themes: storeThemes,
    };
  },

  computed: {
    storeIcon() {
      return this.themes.find((icon) => icon.name === this.name).icon;
    },
  },
};
</script>

<template>
  <TableRowFrame @emitClick="open">
    <template v-slot:colOne>
      <div class="flex items-center">
        <StoresAvatar :name="store.themeColor" />
        <div class="min-w-0 pl-3 text-sm">
          <div class="truncate font-medium text-neutral-900">
            {{ store.name }}
          </div>
          <div class="truncate text-neutral-400">
            {{ `${store.streetAddress}, ${store.zipCode} ${store.city}` }}
          </div>
        </div>
      </div>
    </template>
    <template v-slot:colTwo>
      <div class="truncate flex items-center text-sm">
        {{ store.organisation.name }}
      </div>
    </template>
    <template v-slot:colThree>
      <div class="inline-flex items-center text-sm">
        <country-flag :country="store.country.code" size="small" />
        <span class="ml-2">{{ countryName }}</span>
      </div>
    </template>
    <template v-slot:colFour>
      <UserAvatarList :users="store.users" />
    </template>
    <template v-slot:colFive>
      <OverflowMenu class="flex items-center" :items="menuItems" />
    </template>
  </TableRowFrame>
</template>

<script>
import TableRowFrame from "@/components/user-management/TableRowFrame";
import StoresAvatar from "@/components/user-management/stores/StoresAvatar";
import OverflowMenu from "@/components/common/OverflowMenu";
import UserAvatarList from "@/components/common/UserAvatarList";

import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  components: {
    OverflowMenu,
    TableRowFrame,
    UserAvatarList,
    StoresAvatar,
  },

  props: {
    store: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      menuItems: [
        {
          name: this.$t("global.viewDetails"),
          showIf: true,
          callback: () => {
            this.open();
          },
        },
      ],
      visibleUsers: 3,
    };
  },

  computed: {
    countryName() {
      return countries.getName(this.store.country.code, this.$i18n.locale);
    },
    firstUsers() {
      return this.store.users
        ? this.store.users.slice(0, this.visibleUsers)
        : [];
    },
  },

  methods: {
    open() {
      this.$emit("open", this.store);
    },
  },
};
</script>

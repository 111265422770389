<template>
  <ModalSide @close="closeModal">
    <template v-slot:tabs>
      <button
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>
          {{ $t("components.userManagement.stores.storesForm.storeDetails") }}
        </span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>
    </template>

    <template v-slot:content>
      <ModalSideContent class="flex-grow overflow-y-scroll">
        <StoresForm
          :organisations="organisations"
          :users="users"
          :country-options="countryOptions"
          @updateThemeColor="updateThemeColor"
          @updateName="updateName"
          @updateStreetAddress="updateStreetAddress"
          @updateZipCode="updateZipCode"
          @updateCity="updateCity"
          @updateOrganisationUuid="updateOrganisationUuid"
          @updateCountryUuid="updateCountryUuid"
          @updateSelectedUserUuids="updateSelectedUserUuids"
        />
        <div
          v-if="formError"
          class="bg-danger-0 rounded-sm border-l-2 border-danger-100 px-3 py-2 text-xs font-medium text-danger-500"
        >
          {{ formError }}
        </div>
      </ModalSideContent>

      <ModalSideActions class="justify-end">
        <BaseButton
          :text="$t(`global.cancel`)"
          size="medium"
          variant="outline"
          @buttonClick="closeModal"
        ></BaseButton>

        <BaseButton
          :text="$t(`global.saveChanges`)"
          size="medium"
          variant="fill"
          class="ml-2"
          @buttonClick="submit"
        >
        </BaseButton>
      </ModalSideActions>
    </template>
  </ModalSide>
</template>

<script>
import ModalSide from "@/components/common/ModalSide";
import ModalSideContent from "@/components/common/ModalSideContent";
import ModalSideActions from "@/components/common/ModalSideActions";

import StoresForm from "@/components/user-management/stores/StoresForm";

import CREATE_STORE from "@/graphql/CreateStore.gql";
import FIND_STORES from "@/graphql/FindStores.gql";

export default {
  components: {
    ModalSide,
    ModalSideContent,
    ModalSideActions,
    StoresForm,
  },

  props: {
    organisations: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    countryOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      formError: null,
      name: null,
      themeColor: null,
      streetAddress: null,
      organisationUuid: "-1",
      countryUuid: "-1",
      zipCode: null,
      city: null,
      selectedUserUuids: [],
    };
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },
    updateThemeColor(themeColor) {
      this.themeColor = themeColor;
    },
    updateName(name) {
      this.name = name;
    },
    updateStreetAddress(streetAddress) {
      this.streetAddress = streetAddress;
    },
    updateCity(city) {
      this.city = city;
    },
    updateZipCode(zipCode) {
      this.zipCode = zipCode;
    },
    updateOrganisationUuid(organisationUuid) {
      this.organisationUuid = organisationUuid;
      this.$emit("selectedOrganisationUuid", organisationUuid);
    },
    updateCountryUuid(countryUuid) {
      this.countryUuid = countryUuid;
    },
    updateSelectedUserUuids(selectedUsers) {
      this.selectedUserUuids = selectedUsers;
    },
    submit() {
      this.$apollo
        .mutate({
          mutation: CREATE_STORE,
          variables: {
            name: this.name,
            themeColor: this.themeColor,
            streetAddress: this.streetAddress,
            zipCode: this.zipCode,
            city: this.city,
            organisationUuid: this.organisationUuid,
            countryUuid: this.countryUuid,
            userUuids: this.selectedUserUuids,
          },
          update: (store, { data: { createdStore } }) => {
            const data = store.readQuery({
              query: FIND_STORES,
            });
            data.stores.unshift(createdStore);
            store.writeQuery({ query: FIND_STORES, data });
          },
        })
        .then(() => {
          this.closeModal();
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message); //@TODO: replace with proper error logging later and remove formError cause it's never rendered
        });
    },
  },
};
</script>
